
<template>
    <div class="querycommunity">
        <LiefengContent>
            <template v-slot:title>推送记录</template>
            <template v-slot:contentArea>
                <LiefengTable :talbeColumns="columns" :tableData="data" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    created() {
        this.getList()
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    align: "center",
                    minWidth: 120,
                    title: "推送标题",
                    key: "title",
                    render: (h, params) => {
                        return h("div", [h("span", {}, this.$route.query.title || "")])
                    },
                },
                {
                    align: "center",
                    minWidth: 120,
                    title: "推送信息编码",
                    key: "title",
                    render: (h, params) => {
                        return h("div", [h("span", {}, this.$route.query.code || "")])
                    },
                },
                { align: "center", minWidth: 120, title: "推送人数", key: "pushSize" },
                {
                    align: "center",
                    minWidth: 120,
                    title: "推送对象",
                    key: "title",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "span",
                                {},
                                params.row.pushChannel
                                    ? params.row.pushChannel == "1"
                                        ? "我的用戶"
                                        : params.row.pushChannel == "2"
                                        ? "我的管理员"
                                        : params.row.pushChannel == "3"
                                        ? "我的畅联组"
                                        : params.row.pushChannel == "4"
                                        ? "社区访问用户"
                                        : params.row.pushChannel == "5"
                                        ? "社区注册用户"
                                        : "未知"
                                    : ""
                            ),
                        ])
                    },
                },
                {
                    align: "center",
                    title: "推送时间",
                    key: "taskCount",
                    minWidth: 200,
                    render: (h, params) => {
                        return h("div", [h("span", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")])
                    },
                },
                { align: "center", minWidth: 120, title: "操作人", key: "staffName" },
            ],
            data: [],
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    methods: {
        getList() {
            this.loading = true
            this.$get("/gateway/syinfopublish/api/pc/information/v2/selectPushLog", {
                informationId: this.$route.query.informationId,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.data = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                    } else {
                        this.$Message.error({
                            content: "数据获取失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },
    },
}
</script>

<style scoped lang="less">
.querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td {
        height: calc(100vh - 150px);
        border-left: 1px solid #f8f8f9;
        border-bottom: 1px solid #f8f8f9;
        border-right: 1px solid #f8f8f9;
    }
}
</style>